import React from "react";
import { ICard } from "../../Models/CardModels";
import "./Card.css";
import { loadCard } from "../CardLoader/CardLoader";

export interface ICardProps {
  card: ICard | null;
}

export default class Card extends React.Component<ICardProps> {
  public render() {
    return loadCard(this.props.card || undefined);
  }
}
