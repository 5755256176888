import React from 'react';
import logo from './logo.svg';
import './App.css';
import Card from './Components/Card/Card';
import { CardSuit, CardValue, ICard } from './Models/CardModels';
import { createShuffledDeck } from './Helpers/Deck';

interface IAppState {
  deck: ICard[];
}

class App extends React.Component<{}, IAppState> {
  state = { deck: [] };

  render() {
    const { deck } = this.state;

    let card = null;
    if (deck.length) {
      card = deck[0];
    }
    let remainingCards = deck.length ? deck.length -1 : 52;
    return (
      <div className="App" onClick={this.nextCard}>
        <p>Click to see next card</p>
        <div className="card-container">
          <Card card={card}/>
        </div>
        <p>Cards Remaining: {remainingCards}</p>
      </div>
    );
  }

  private nextCard = () => {
    const { deck } = this.state;

    if (deck.length) {
      this.setState({deck: deck.slice(1)});
    } else {
      this.setState({deck: createShuffledDeck()});
    }
  }
  
}

export default App;
