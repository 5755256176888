// Fisher yates shuffle
const progressRandomSort = async (items: any[], progressCallback: (() => Promise<void>) | null = null) => {
    for (let i = 0; i < items.length - 1; i++) {
      //  j ← random integer such that i ≤ j < n
      let j = Math.floor(Math.random() * (items.length - i)) + i;
  
      // exchange a[i] and a[j]
      const temp = items[i];
      items[i] = items[j];
      items[j] = temp;
      progressCallback && await progressCallback();
    }
  };
  
  export default progressRandomSort;