
export interface ICard {
    deckId: number;
    value: CardValue;
    suit: CardSuit;
}

export interface IPlayableCard {
    card: ICard;
    canPlay: boolean;
}

export interface ISelectableCard {
    selected: boolean;
    playableCard: IPlayableCard;
}

export interface ISelectableHiddenCard {
    selected: boolean;
}

export interface ISourcedCard {
    source: PlayerHand;
    card: ICard;
}

export enum PlayerHand {
    HAND = 0,
    VISIBLE = 1,
    HIDDEN = 2
}

export enum CardValue {
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    NINE = 9,
    TEN = 10,
    JACK = 11,
    QUEEN = 12,
    KING = 13,
    ACE = 14
}

export enum CardSuit {
    HEARTS = 0,
    DIAMONDS = 1,
    CLUBS = 2,
    SPADES = 3
}

export const HIDDEN_SOURCED_CARD: ISourcedCard = {card: { suit:0, value:0, deckId:0 }, source:PlayerHand.HIDDEN};