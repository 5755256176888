import { ICard } from "../Models/CardModels";
import progressRandomSort from "./Sort";


// Fisher yates shuffle
export const createDeck = () => {
    let cards: ICard[] = [];
    for (let i = 0; i < 4; i++) {
        for (let j = 2; j < 15; j++) {
            cards.push({deckId:0, suit: i, value: j});
        }
    }
    return cards;
  };

  export const createShuffledDeck = () => {
    const cards = createDeck();
    progressRandomSort(cards);

    return cards;
  }